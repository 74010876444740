<template>
  <div class="join-us">
    <b-container>
      <h2 class="my-4" v-if="page">{{ $t('加入大豐') }}</h2>
    </b-container>
    <b-img :src="require('@/assets/images/sub_banner1.jpg')" :alt="$t('加入大豐')"></b-img>
    <b-container>
      <b-tabs content-class="mt-3" class="jobs-tabs my-4" lazy justified align="center" v-model="tabsId">
        <b-tab @click="jobOpportunity !== '' ? (currentTitle = jobOpportunity.title) : handleTab('job-opportunity')">
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-center">
              <strong class="mx-2">{{ $t('工作機會') }}</strong>
            </div>
          </template>
          <template v-if="content">
            <b-overlay :show="loading" rounded="sm">
              <div class="html-content" v-html="jobOpportunity !== '' ? jobOpportunity.content : content.content"></div>
            </b-overlay>
          </template>
          <div class="job-list">
            <div class="card my-4" v-for="jobsList in jobsArr" :key="`jobsList_${jobsList.id}`">
              <div class="title my-0 p-md-4 p-2 pr-5 pr-md-5" role="tab" v-b-toggle="`accordion-${jobsList.id}`">
                {{ jobsList.title }}
              </div>

              <b-collapse class="mt-2 collapse px-md-4 px-2" :id="`accordion-${jobsList.id}`">
                <div class="collapse-content mb-3">
                  <div class="content" v-html="jobsList.content"></div>
                  <div class="btn-group">
                    <b-link
                      class="btn orange col-12 col-md-auto my-1"
                      :to="{ name: 'join-us-form', query: { jobId: jobsList.id } }"
                    >
                      <em class="icon online-app" :title="$t('網上申請')"></em>
                      {{ $t('網上申請') }}
                    </b-link>
                    <b-link rel="" :to="jobsList.applyForm" target="_blank" class="btn orange col-12 col-md-auto my-1">
                      <em class="icon download-app" :title="$t('下載申請表')"></em>
                      {{ $t('下載申請表') }}
                    </b-link>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </b-tab>
        <b-tab @click="benefits !== '' ? (currentTitle = benefits.title) : handleTab('benefits')">
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-center">
              <strong class="mx-2">{{ $t('福利保障') }}</strong>
            </div>
          </template>
          <template v-if="content">
            <b-overlay :show="loading" rounded="sm">
              <div class="html-content" v-html="benefits !== '' ? benefits.content : content.content"></div>
            </b-overlay>
          </template>
        </b-tab>
        <b-tab
          @click="talentDevelopment !== '' ? (currentTitle = talentDevelopment.title) : handleTab('talent-development')"
        >
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-center">
              <strong class="mx-2">{{ $t('人才發展') }}</strong>
            </div>
          </template>
          <template v-if="content">
            <b-overlay :show="loading" rounded="sm">
              <div
                class="html-content"
                v-html="talentDevelopment !== '' ? talentDevelopment.content : content.content"
              ></div>
            </b-overlay>
          </template>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {},
  data() {
    return {
      tabsId: 0,
      loading: false,
      jobOpportunity: '',
      talentDevelopment: '',
      benefits: '',
      jobsArr: null,
      content: null,
      currentTitle: null
    }
  },
  watch: {
    currentStep(n) {
      if (n) window.scrollTo(0, 0)
    },
    tabsId(o, n) {
      // this.$router.push(`${this.$route.path}#tab${this.tabsId + 1}`)
      this.handleTab(this.tabsId == 0 ? 'job-opportunity' : this.tabsId == 1 ? 'benefits' : 'talent-development')
      // console.log(1111,o,n)
    }
  },
  computed: {
    ...mapState('joinus', ['joinUs']),
    ...mapState('common', ['page'])
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid, tabsId } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path:
              tabsId == undefined || tabsId == 0 ? 'job-opportunity' : tabsId == 1 ? 'benefits' : 'talent-development',
            cookies,
            id
          }),
          this.$store.dispatch('joinus/FETCH_APPLY_JOBS', {
            cookies,
            params: { preview, id, vid }
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {
      this.jobsArr = this.joinUs.jobs
      if (this.page) {
        this.content = this.page
        this.currentTitle = this.page.title
      }
      this.tabsId = Number(this.$route.hash.split('#tab')[1] - 1)
    },
    handleTab(item) {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      const { isPreview, vid } = this.$route.query
      this.loading = true
      return new Promise((resolve, reject) => {
        Promise.all([
          this.$store.dispatch('common/FETCH_PAGE', {
            path: item,
            isPreview: parseInt(isPreview) == 1,
            vid: parseInt(vid)
          })
        ])
          .then(() => {
            item == 'job-opportunity'
              ? (this.jobOpportunity = this.page)
              : item == 'benefits'
              ? (this.benefits = this.page)
              : (this.talentDevelopment = this.page)
            this.currentTitle = this.page.title
            resolve()
          })
          .catch(err => {
            reject()
          })
          .finally(() => {
            this.loading = false
          })
      })
      // window.location.href = this.$route.path + '?tabsId=' + this.tabsId
    }
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  serverPrefetch() {
    return this.fetchData()
  },
  mounted() {
    this.fetchData()
  }
}
</script>
